<template>
  <div id="companyNums">
    <el-card style="position: relative">
      <header>
        <h2 style="display: flex; align-items: center">
          <el-page-header
            @back="goBack"
            title="返回上页"
            content="公司公海"
          ></el-page-header>
          <span v-if="showTitleText"
            >(查看【公司公海】所有导入未分配的号码以及员工端、组长端释放回来的数据。<span
              style="color: red"
              >如导入数据，部门名称填写错误，会默认导入公司公海</span
            >)</span
          >
          <el-tooltip
            class="item"
            effect="dark"
            content="点击查看详情说明"
            placement="top"
          >
            <img
              v-if="!showTitleText"
              src="@/assets/images/what.png"
              alt=""
              style="width: 30px; height: 30px; cursor: pointer"
              @click="showTitleText = true"
            />
          </el-tooltip>
        </h2>
        <div class="search">
          <div class="left">
            <div class="left-wrap">
              <span>号码</span>
              <el-input
                v-model="activePhone"
                placeholder="请输入完整号码"
                size="mini"
                clearable
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="handleSearch"
                ></el-button>
              </el-input>
            </div>

            <div style="margin-left: 10px">
              <el-button
                size="mini"
                v-show="isDivVisible"
                @click="setisDivVisible(false)"
                >取消高级搜索</el-button
              >
              <el-button
                type="success"
                v-show="!isDivVisible"
                plai
                size="mini"
                @click="isDivVisible = true"
                >高级搜索</el-button
              >
            </div>
            <div>
              <el-button
                v-show="!showBtnList"
                style="margin-left: 10px"
                type="success"
                @click="showBtnList = true"
                size="mini"
                class="searchBtn"
                >更多设置
              </el-button>
              <div
                v-show="showBtnList"
                class="transition-box"
                style="margin-left: 10px"
              >
                <el-button
                  v-show="showBtnList"
                  icon="el-icon-d-arrow-right"
                  size="mini"
                  @click="showBtnList = false"
                  >收起</el-button
                >
                <el-button
                  type="success"
                  icon="el-icon-search"
                  @click="handleSearch"
                  size="mini"
                  class="searchBtn"
                  >查找</el-button
                >
                <el-button
                  class="reset"
                  type="success"
                  icon="el-icon-refresh"
                  @click="reset()"
                  size="mini"
                  >重置</el-button
                >
                <el-tooltip
                  content="可以将导入的数据进行分配给【部门公海】以及单个坐席进行拨打"
                  effect="light"
                  placement="top"
                >
                  <el-button
                    type="success"
                    @click="distributeNums()"
                    size="mini"
                    class="distributeNums"
                    v-if="roleName == '管理员' || roleName == '企业'"
                    >分配号码</el-button
                  ></el-tooltip
                >
                <el-tooltip
                  content="可以将导入的数据选择任意数量平均分配员工"
                  effect="light"
                  placement="top"
                >
                  <el-button
                    type="success"
                    icon="el-icon-folder-opened"
                    @click="distributionShow = true"
                    size="mini"
                    v-if="roleName == '管理员' || roleName == '企业'"
                    class="allNums"
                    >平均分配号码</el-button
                  ></el-tooltip
                >
                <el-tooltip
                  content="导入要拨打的数据（下载模版，模板里面手机号必填）进行管理"
                  effect="light"
                  placement="top"
                >
                  <el-button
                    type="success"
                    icon="el-icon-folder-opened"
                    @click="allNums()"
                    size="mini"
                    class="allNums"
                    v-if="roleName == '管理员' || roleName == '企业'"
                    >批量导入号码</el-button
                  ></el-tooltip
                >
                <el-tooltip
                  content="可以进行单个客户号码进行添加"
                  effect="light"
                  placement="top"
                >
                  <el-button
                    type="success"
                    icon="el-icon-folder-opened"
                    @click="addNums()"
                    size="mini"
                    class="addNums"
                    v-if="roleName == '管理员' || roleName == '企业'"
                    >添加号码</el-button
                  ></el-tooltip
                >
                <el-tooltip
                  content="回收/批量回收：将已拨打的数据进行回收到【回收站】进行删除"
                  effect="light"
                  placement="top"
                >
                  <el-button
                    type="danger"
                    @click="moreDelete()"
                    class="delete"
                    size="mini"
                    icon="el-icon-delete"
                    v-if="roleName == '管理员' || roleName == '企业'"
                    >批量回收</el-button
                  ></el-tooltip
                >
                <el-button
                  type="primary"
                  @click="allApply"
                  size="mini"
                  icon="el-icon-folder-add"
                  v-if="roleName != '企业'"
                  >批量申请号码</el-button
                >
              </div>
            </div>
            <!-- <div class="left-wrap">
            <span>拨打时间</span>
            <el-date-picker v-model="changeTime" type="datetimerange" range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期" align="center" size="mini" :picker-options="pickerOptions">
            </el-date-picker>
          </div> -->
            <!-- <div class="left-wrap">
            <span>更新时间</span>
            <el-date-picker v-model="getTime" type="datetimerange" range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期" align="center" size="mini" :picker-options="pickerOptions">
            </el-date-picker>
          </div> -->
          </div>
          <transition name="el-zoom-in-top">
            <div v-show="isDivVisible" class="transition-box left vipSearch">
              <div class="left-wrap">
                <span>{{ getNewLabel("企业", "company") }}</span>
                <el-input
                  v-model="company"
                  :placeholder="`请输入${getNewLabel('企业', 'company')}`"
                  size="mini"
                ></el-input>
              </div>
              <div class="left-wrap">
                <span>{{ getNewLabel("行业", "industry") }}</span>
                <el-input
                  v-model="industry"
                  :placeholder="`请输入${getNewLabel('行业', 'industry')}`"
                  size="mini"
                ></el-input>
              </div>
              <div class="left-wrap">
                <span>客户来源</span>
                <el-select
                  v-if="ifParentId == 7"
                  v-model="customerSource"
                  placeholder="请选择客户来源"
                  filterable
                  size="mini"
                >
                  <el-option
                    v-for="(item, index) in customerSourceOptions"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-else
                  v-model="customerSource"
                  placeholder="请输入客户来源"
                  size="mini"
                ></el-input>
              </div>
              <div class="left-wrap">
                <span>备注</span>
                <el-input
                  v-model="notes"
                  placeholder="请输入备注"
                  size="mini"
                ></el-input>
              </div>
              <div class="left-wrap">
                <span>归属人</span>
                <el-input
                  v-model="userRealname"
                  placeholder="请输入归属人"
                  size="mini"
                ></el-input>
              </div>
              <div class="left-wrap">
                <span>状态</span>
                <el-select
                  v-model="value1"
                  placeholder="请选择状态"
                  filterable
                  size="mini"
                >
                  <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="left-wrap">
                <span>导入时间</span>
                <el-date-picker
                  v-model="createTime"
                  type="datetimerange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  align="center"
                  size="mini"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </div>
            </div>
          </transition>
          <div class="right"></div>
        </div>
      </header>
      <!-- <hr> -->
      <hr />
      <main>
        <el-table
          :data="tableData"
          :class="filterEnterprise()"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
          @row-dblclick="dblclick"
        >
          <!-- 多选按钮 -->
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <!-- <el-table-column prop="userRealname" label="归属人" align="center"></el-table-column> -->
          <el-table-column
            prop="activePhone"
            label="手机号码"
            align="center"
            fixed
            width="120"
            v-if="(!hideNum && id != 5051) || roleName == '企业'"
          ></el-table-column>
          <el-table-column
            prop="phone"
            label="手机号码"
            align="center"
            fixed
            width="120"
            v-if="(hideNum || id == 5051) && roleName != '企业'"
          ></el-table-column>
          <!-- <el-table-column prop="company" label="企业名称" align="center"></el-table-column>
        <el-table-column prop="customerPhone" label="姓名" align="center"></el-table-column>
        <el-table-column prop="industry" label="行业" align="center"></el-table-column>
        <el-table-column prop="address" label="地址" align="center"></el-table-column>
        <el-table-column prop="createTime" label="导入时间" align="center"></el-table-column>
        <el-table-column prop="changeTime" label="拨打时间" align="center"></el-table-column> -->
          <!-- <el-table-column
          prop="departId"
          label="部门ID"
          align="center"
        ></el-table-column> -->
          <!-- <el-table-column prop="remarks" label="备注" align="center"></el-table-column> -->
          <el-table-column
            prop="status"
            label="状态"
            align="center"
            width="100"
          >
            <template #default="scope">
              <span v-if="scope.row.status == 0">一般客户</span>
              <span v-if="scope.row.status == 1">意向客户</span>
              <span v-if="scope.row.status == 2">无意向客户</span>
              <span v-if="scope.row.status == 3">成交客户</span>
              <span v-if="scope.row.status == 4">黑名单</span>
              <span v-if="scope.row.status == 5">无状态</span>
              <span v-if="scope.row.status == 6">未接通</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="choosePeople" label="决策人" align="center"></el-table-column>
        <el-table-column prop="customerScale" label="公司规模" align="center"></el-table-column>
        <el-table-column prop="customerSource" label="客户来源" align="center"></el-table-column> -->
          <el-table-column
            :prop="v.prop"
            :label="v.label"
            align="center"
            v-for="v in newColumnsData"
            :key="v.key"
            width="160"
          ></el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="300"
            align="center"
          >
            <template #default="scope">
              <el-button
                type="success"
                @click="apply(scope.row)"
                v-if="roleName != '企业'"
                size="mini"
                style="margin-left: 0"
                >申请号码</el-button
              >
              <el-button
                type="success"
                @click="update(scope)"
                size="mini"
                v-if="roleName == '管理员' || roleName == '企业'"
                >编辑</el-button
              >
              <!-- 回收号码 -->
              <el-button
                type="danger"
                @click="removeButton(scope.row)"
                v-if="roleName == '管理员' || roleName == '企业'"
                size="mini"
                >回收</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </main>
    </el-card>

    <!--分页  -->
    <div class="pagination">
      <el-pagination
        background
        layout="total, prev, pager, next,sizes,jumper"
        :current-page="pageIndex"
        :page-size="pageSize"
        :page-sizes="[10, 20, 50, 100, 200]"
        :total="pageTotal"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <!-- 双击弹出框 -->
    <el-dialog title="查看详情" :visible.sync="wrapVisible">
      <el-tabs v-model="activeName">
        <el-tab-pane label="详情" name="update">
          <el-form ref="form" :model="form" label-width="100px" class="update">
            <el-form-item label="客户号码">
              <el-input
                v-model="currentRow.phone"
                placeholder="无"
                v-if="(hideNum || id == 5051) && roleName != '企业'"
                disabled
              ></el-input>
              <el-input
                v-model="currentRow.activePhone"
                placeholder="无"
                v-if="(!hideNum && id != 5051) || roleName == '企业'"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="v.label"
              v-for="v in newColumnsData"
              :key="v.key"
            >
              <el-input
                v-model="currentRow[v.prop]"
                placeholder="无"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <span v-if="currentRow.status == 0">一般客户</span>
              <span v-else-if="currentRow.status == 1">意向客户</span>
              <span v-else-if="currentRow.status == 2">无意向客户</span>
              <span v-else-if="currentRow.status == 3">成交客户</span>
              <span v-else-if="currentRow.status == 4">黑名单</span>
              <span v-else>无状态</span>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="通话记录" name="callRecord">
          <el-row>
            <el-col :span="24">
              <el-table :data="soundTableDate" style="width: 100%">
                <el-table-column
                  prop="activePhone"
                  label="主叫"
                  align="center"
                  v-if="
                    (!hideNum && id != 5051) ||
                    realname == 'admin' ||
                    roleName == '企业'
                  "
                ></el-table-column
                ><el-table-column
                  prop="phone"
                  label="主叫"
                  align="center"
                  v-if="
                    id != 5051 &&
                    roleName != '企业' &&
                    hideNum &&
                    realname != 'admin'
                  "
                ></el-table-column>
                <el-table-column
                  prop="passivePhone"
                  label="被叫"
                  align="center"
                  v-if="
                    (!hideNum && id != 5051) ||
                    realname == 'admin' ||
                    roleName == '企业'
                  "
                ></el-table-column>
                <el-table-column
                  prop="phone1"
                  label="被叫"
                  align="center"
                  v-if="
                    id != 5051 &&
                    roleName != '企业' &&
                    hideNum &&
                    realname != 'admin'
                  "
                ></el-table-column>
                <el-table-column
                  prop="seconds"
                  label="秒数(s)"
                  sortable
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="callStarttime"
                  label="开始时间"
                  sortable
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="callEndtime"
                  sortable
                  label="结束时间"
                  align="center"
                ></el-table-column>
                <el-table-column align="center">
                  <template #default="scope">
                    <el-button
                      type="primary"
                      icon="el-icon-search"
                      @click="startaudio(scope)"
                      v-if="scope.row.recordUrl != null"
                      size="mini"
                      >查看录音</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="跟进记录" name="trailLog">
          <el-row>
            <el-col :span="24">
              <el-table :data="upRecords" style="width: 100%">
                <el-table-column prop="data" label="跟进日期" width="">
                </el-table-column>
                <el-table-column prop="content" label="跟进内容" width="">
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!-- 批量回收弹出框 -->
    <el-dialog title="提示" :visible.sync="allRemoveVisible" width="30%">
      <span>确定要回收所选内容吗？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="success" @click="allRemoveVisible = false"
            >取 消</el-button
          >
          <el-button type="success" @click="submitDeleteList()"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 回收弹出框 -->
    <el-dialog title="回收" :visible.sync="removeVisible" width="30%">
      <span>确定回收这段号码吗？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="success" @click="removeVisible = false"
            >取 消</el-button
          >
          <el-button type="success" @click="submitRemoveValue()"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" :visible.sync="updateVisible">
      <el-form ref="form" :model="form" label-width="100px" class="update">
        <el-form-item label="客户号码">
          <el-input
            v-model="form.phone"
            placeholder="请输入客户号码"
            v-if="(hideNum || id == 5051) && roleName != '企业'"
          ></el-input>
          <el-input
            v-model="form.activePhone"
            placeholder="请输入客户号码"
            v-if="(!hideNum && id != 5051) || roleName == '企业'"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户姓名">
          <el-input
            v-model="form.customerPhone"
            placeholder="请输入客户姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input
            v-model="form.company"
            placeholder="请输入公司名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="决策人">
          <el-input
            v-model="form.choosePeople"
            placeholder="请输入决策人"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司规模">
          <el-input
            v-model="form.customerScale"
            placeholder="请输入公司规模"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input
            v-model="form.company"
            placeholder="请输入公司名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户来源">
          <el-select
            v-if="ifParentId == 7"
            v-model="form.customerSource"
            placeholder="请选择客户来源"
            filterable
            size="mini"
          >
            <el-option
              v-for="(item, index) in customerSourceOptions"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-input
            v-else
            v-model="form.customerSource"
            placeholder="请输入客户来源"
          ></el-input>
        </el-form-item>
        <el-form-item label="行业">
          <el-input v-model="form.industry" placeholder="请输入行业"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="form.address" placeholder="请输入地址"></el-input>
        </el-form-item>
        <!-- <el-form-item label="备注">
          <el-input v-model="form.remarks" placeholder="请输入备注"></el-input>
        </el-form-item> -->
        <el-form-item label="状态">
          <el-radio-group v-model="update_status" style="line-height: 30px">
            <el-radio :label="0">一般客户</el-radio>
            <el-radio :label="1">意向客户</el-radio>
            <el-radio :label="2">无意向客户</el-radio>
            <el-radio :label="3">成交客户</el-radio>
            <el-radio :label="4">黑名单</el-radio>
            <el-radio :label="5">无状态</el-radio>
            <el-radio :label="6">未接通</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="success" @click="updateVisible = false"
            >取 消</el-button
          >
          <el-button type="success" @click="updateSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 添加号码弹出框 -->
    <el-dialog title="添加号码" :visible.sync="addOneVisible">
      <el-form ref="form" :model="form" label-width="100px" class="update">
        <el-form-item label="客户号码">
          <el-input
            v-model="form.activePhone"
            placeholder="请输入客户号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户姓名">
          <el-input
            v-model="form.customerPhone"
            placeholder="请输入客户姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司名称" v-if="realname == 'admin'">
          <el-input
            v-model="form.company"
            placeholder="输入公司名称分配至公司公海"
          ></el-input>
        </el-form-item>
        <el-form-item label="决策人">
          <el-input
            v-model="form.choosePeople"
            placeholder="请输入决策人"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司规模">
          <el-input
            v-model="form.customerScale"
            placeholder="请输入公司规模"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input
            v-model="form.company"
            placeholder="请输入公司名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户来源">
          <el-select
            v-if="ifParentId == 7"
            v-model="form.customerSource"
            placeholder="请选择客户来源"
            filterable
            size="mini"
          >
            <el-option
              v-for="(item, index) in customerSourceOptions"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-input
            v-else
            v-model="form.customerSource"
            placeholder="请输入客户来源"
          ></el-input>
        </el-form-item>
        <el-form-item label="行业">
          <el-input v-model="form.industry" placeholder="请输入行业"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="form.address" placeholder="请输入地址"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remarks" placeholder="请输入备注"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="update_status" style="line-height: 30px">
            <el-radio :label="0">一般客户</el-radio>
            <el-radio :label="1">意向客户</el-radio>
            <el-radio :label="2">无意向客户</el-radio>
            <el-radio :label="3">成交客户</el-radio>
            <el-radio :label="4">黑名单</el-radio>
            <el-radio :label="5">无状态</el-radio>
            <el-radio :label="6">未接通</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="success" @click="addOneVisible = false"
            >取 消</el-button
          >
          <el-button type="success" @click="addOneSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 批量导入号码弹出框 -->
    <el-dialog
      title="批量导入号码"
      :visible.sync="numShow"
      width="70%"
      class="num-upload"
      @close="clearNumber"
    >
      <div class="left">
        <div style="display: flex; align-items: center; margin-bottom: 10px">
          <div style="margin-right: 10px">号码检测</div>
          <el-switch
            style="display: block"
            v-model="numberTest"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="开启"
            inactive-text="关闭"
          >
          </el-switch>
        </div>
        <el-button
          type="success"
          icon="el-icon-download"
          @click="upload()"
          class="uploadBtn"
          style="width: 100%"
          >点击下载模板
        </el-button>
        <el-upload
          ref="uplaod"
          class="upload-demo"
          action=""
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :auto-upload="false"
          multiple
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="fileList"
          :on-change="changeUpload"
          :drag="true"
          width="100%"
          accept=".xls,.xlsx"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div class="right">
        <div style="line-height: 36px; color: #606369">
          <p>
            1、不论在管理员账号的（客户列表、个人私海、部门公海、公司公海）导入客户名单，在导入表格里面只填写了手机号码，那么最终都会导入到【公司公海】
          </p>
          <p>
            2、不论在管理员账号的（客户列表、个人私海、部门公海、公司公海）导入客户名单，在导入表格里面填写了手机号、以及坐席账户、部门名字，最终都会到对应的【部门公海】
          </p>
          <p style="color: red">
            注意：如需要把客户名单导入部门，则必须由管理员创建部门后，才能够成功导入部门，反之则不能；如导入数据，部门名称填写错误，会默认导入公司公海
          </p>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <!-- <el-button @click="numShow = false">取 消</el-button> -->
          <el-button
            @click="numsSubmit"
            :disabled="disabled"
            type="success"
            style="width: 100%"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 分配号码弹出框 -->
    <el-dialog
      title="分配号码(自动过滤黑名单)"
      :visible.sync="distributeShow"
      width="30%"
    >
      <el-switch
        v-model="departUser"
        active-text="选择个人"
        inactive-text="选择部门"
      >
      </el-switch>
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        style="margin-top: 30px"
      >
        <el-form-item label="部门">
          <el-select
            v-model="departId"
            filterable
            placeholder="请选择部门，分配至部门海"
            :disabled="departUser"
          >
            <el-option
              v-for="item in underDepart"
              :key="item.id"
              :label="item.departName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="个人">
          <el-select
            v-model="userId"
            filterable
            placeholder="请选择个人"
            :disabled="!departUser"
          >
            <el-option
              v-for="item in underUser"
              :key="item.id"
              :label="item.realname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="success" @click="nextSubmit">下一步</el-button>
          <el-button type="success" @click="distributeShow = false"
            >退 出</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 平均分配号码弹出框 -->
    <el-dialog
      title="平均分配(将客户平均分配给选择的员工)"
      :visible.sync="distributionShow"
      width="30%"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        style="margin-top: 30px"
      >
        <el-form-item label="分配公海前(个)" label-width="200">
          <el-input-number
            v-model="distributionCount"
            :precision="0"
            controls-position="right"
            :min="1"
            :max="getMaxNum()"
            size="mini"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <el-divider content-position="left">选择要分配的员工</el-divider>
      <el-cascader-panel
        :options="optionsUser"
        v-model="sprVal"
        ref="cascaderBox"
        @change="getCheckUser"
      ></el-cascader-panel>
      <div class="spr-selected" style="margin-top: 10px">
        <p v-if="sprValArr.length != 0" style="margin-bottom: 6px">
          已选择的员工({{ sprValArr.length }}/10):
        </p>
        <div
          v-for="(item, i) in sprValArr"
          :key="item.value"
          style="display: inline-block"
        >
          <el-button style="margin-bottom: 10px; position: relative"
            >{{ item.label
            }}<i
              class="el-icon-circle-close"
              @click="delUser(i)"
              style="color: red; position: absolute; top: -20%; right: -10%"
            ></i
          ></el-button>
          <span
            style="
              font-weight: bold;
              font-size: 18px;
              margin-right: 4px;
              color: rgb(65, 187, 132);
            "
            v-if="i != sprValArr.length - 1"
          ></span>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="success" @click="sprValArr = []">清 空</el-button>
          <el-button type="success" @click="distributionShow = false"
            >取 消</el-button
          >
          <el-button type="success" @click="distributionSubmit"
            >确 认</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 选择分配路径弹出框 -->
    <el-dialog title="选择分配路径" :visible.sync="taskShow" width="30%">
      <span>是否将号码分配到呼叫任务</span>
      <template #footer>
        <span class="dialog-footer">
          <el-tooltip
            content="没有指定任务，数据会到员工的【个人私海】"
            effect="light"
            placement="top"
          >
            <el-button type="success" @click="distributeSubmit"
              >不分配到呼叫任务</el-button
            ></el-tooltip
          >
          <el-tooltip
            content="建立任务名称以及完成时间，数据到员工的呼叫任务以及【个人私海】"
            effect="light"
            placement="top"
          >
            <el-button
              type="success"
              @click="taskSubmit"
              :disabled="!departUser"
              >分配到呼叫任务</el-button
            ></el-tooltip
          >
        </span>
      </template>
    </el-dialog>
    <!-- 呼叫任务弹出框 -->
    <el-dialog title="呼叫任务" :visible.sync="taskCall" width="30%">
      <el-form ref="form" :model="taskForm" label-width="100px">
        <el-form-item label="任务名称">
          <el-input
            v-model="taskForm.taskName"
            placeholder="请输入任务名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="任务时间">
          <el-date-picker
            v-model="taskForm.taskTime"
            type="datetime"
            placeholder="选择任务时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="success" @click="taskCall = false">取消</el-button>
          <el-button
            type="success"
            @click="taskCallBtn"
            :disabled="disableButton"
            >确定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();

import * as XLSX from "xlsx";
import XlsxPopulate from "xlsx-populate";

import { phoneApplyUserPhoneApplyFor } from "@/api/phoneApplyFor";
import {
  phoneFindUser,
  phoneList,
  phoneDistribute,
  phoneUpdate,
  phoneImportPhone,
  phoneAdd,
  phoneRecycleBin,
} from "@/api/phone";
import { phoneFindOneHistoryPhone, headDistribute } from "@/api/phone";
import { callLogList } from "@/api/callLog";
import { userList1 } from "@/api/user";

export default {
  data() {
    return {
      sprVal: null,
      cities: [],
      cityOptions: [],
      distributionCount: 1,
      distributionDount: 0,
      distributionShow: false,
      notes: null,
      showBtnList: false,
      isDivVisible: false,
      pickerOneDate: "",
      pickerOptions: {
        // 获取选择的第一个时间
        onPick: ({ maxDate, minDate }) => {
          this.pickerOneDate = minDate.getTime();
          if (maxDate) {
            this.pickerOneDate = "";
          }
        },
        disabledDate: (time) => {
          //选择了第一个时间的情况下根据第一个时间设置可选范围
          //未选择第一个时间的情况下可选范围只能选择过去的时间
          if (this.pickerOneDate) {
            const day = 30 * 24 * 3600 * 1000; // 15天
            let maxTime = this.pickerOneDate + day;
            let minTime = this.pickerOneDate - day;
            return time.getTime() > maxTime || time.getTime() < minTime;
          } else {
            // return time.getTime() > Date.now()
          }
        },
      },
      wrapVisible: false,
      activeName: "update",
      upRecords: [],
      currentRow: {},
      soundTableDate: null,
      sprValArr: [],
      fileList: [],
      newColumnsData: [],
      numberTest: false,
      showTitleText: false,
      update_status: null,
      taskForm: {},
      taskCall: false,
      taskShow: false,
      userRealname: null,
      addNoteVisible: false,
      choosePeople: null,
      customerScale: null,
      customerSource: null,
      id: "",
      industry: null,
      address: null,
      oneNum: 0,
      twoNum: 0,
      activePhone: null,
      company: null,
      value: null,
      value1: null,
      disabled: false,
      options1: [
        {
          value: 0,
          label: "一般客户",
        },
        {
          value: 1,
          label: "意向客户",
        },
        {
          value: 2,
          label: "无意向客户",
        },
        {
          value: 3,
          label: "成交客户",
        },
        {
          value: 4,
          label: "黑名单",
        },
        {
          value: 5,
          label: "无状态",
        },
        {
          value: 6,
          label: "未接通",
        },
      ],
      createTime: null,
      changeTime: null,
      getTime: null,
      dialogVisible: false,
      // submitRemoveValue: false,
      allRemoveVisible: false, //批量回收弹出框
      removeVisible: false, //回收弹出框
      updateVisible: false, //编辑弹出框
      addOneVisible: false, //添加弹出框
      distributeShow: false, //分配弹出框
      departUser: false,
      departId: null,
      userId: null,
      departShow: true,
      form: {
        phone: null,
        choosePeople: null,
        customerScale: null,
        customerSource: null,
        createTime: null,
        changeTime: null,
        getTime: null,
        activePhone: "",
        customerPhone: "",
        company: null,
        departName: null,
        industry: null,
        address: null,
        remarks: "",
        status: "",
        userId: "",
        departId: "",
        id: "",
        roleId: "",
      },
      nums: [
        {
          id: 0,
          numName: "部门公海",
        },
        {
          id: 1,
          numName: "公司公海",
        },
      ],
      changeNums: null,
      numsArray: [], //选中号码的ID
      underDepart: [],
      underUser: [],
      remarks: "", //备注（只传不回显）
      numShow: false, //批量导入弹出框
      realname: null,
      indexArr: [],
      isBtn: false,
      hideNum: false,
      roleName: null,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
      optionsUser: [],
      ifParentId: null,
      customerSourceOptions: [],
    };
  },

  computed: {
    disableButton() {
      return !(this.taskForm.taskName && this.taskForm.taskTime);
    },
  },
  activated() {
    // this.newColumnsData = JSON.parse(localStorage.getItem("newColumnsData"));
    this.newColumnsData = JSON.parse(this.getCookie("columnsData"));
  },
  created() {
    this.ifParentId = localStorage.getItem("parentId");
    this.customerSourceOptions = JSON.parse(
      localStorage.getItem("customerSourceOptions")
    );
    this.realname = localStorage.getItem("realname");
    this.$nextTick(function () {
      let bool = localStorage.getItem("hideNum");
      bool == "false" ? (this.hideNum = false) : (this.hideNum = true);
      console.log("hideNum参数", this.hideNum);
    });
    this.roleName = localStorage.getItem("roleName");
    this.id = localStorage.getItem("id") * 1;
    if (this.roleName == "管理员" || this.roleName == "企业") {
      this.findUser();
      this.orderBtn();
    }
    this.getList();
    // this.$router.go(0)
  },
  watch: {
    departUser() {
      this.departId = null;
      this.userId = null;
    },
    tableData: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.$nextTick(() => {
            //newVal是新的表格数据，[8] 8是表格数据要默认选中的索引
            this.indexArr.forEach((item) => {
              this.$refs.multipleTable.toggleRowSelection(newVal[item], true);
            });
          });
        }
      },
      // immediate: true
    },
  },
  methods: {
    // 删除已选择审批人
    delUser(index) {
      this.sprValArr.splice(index, 1);
    },
    getCheckUser(check) {
      if (this.sprValArr.length >= 10) {
        Message.warning("最多同时分配10名员工");
        return false;
      }
      let arr = this.$refs.cascaderBox.getCheckedNodes();
      this.sprValArr.push(arr[0].data);
      // 去重
      let map = new Map();
      for (let item of this.sprValArr) {
        if (!map.has(item.value)) {
          map.set(item.value, item);
        }
      }
      this.sprValArr = [...map.values()];
    },
    // 选择审批人的数据获取
    orderBtn() {
      let params = {};
      let res = userList1(params);
      res.then((res) => {
        let arr = res.data.data.map((v) => {
          return { value: v.depart_id, label: v.depart_name, children: [] };
        });
        // 去重
        let map = new Map();
        for (let item of arr) {
          if (!map.has(item.value)) {
            map.set(item.value, item);
          }
        }
        let newArr = [...map.values()];
        newArr.forEach((v) => {
          res.data.data.forEach((v2) => {
            if (v.value == v2.depart_id) {
              v.children.push({ value: v2.user_id, label: v2.realname });
            }
          });
        });
        this.optionsUser = newArr;
      });
    },
    getMaxNum() {
      if (this.pageTotal * 1 < 10000) {
        return this.pageTotal * 1;
      } else {
        return 10000;
      }
    },
    async distributionSubmit() {
      if (this.sprValArr.length == 0) {
        Message.warning("请选择要分配的员工");
        return false;
      }

      if (this.pageTotal == 0) {
        Message.warning("暂无可分配的号码");
        return false;
      }

      let listUserId = this.sprValArr.map((v) => v.value);
      const loading = await this.$loading({
        lock: true,
        text: "分配号码中,请稍等...",
      });
      this.sprValArr = [];
      let params = {
        listUserId, //分配的员工id
        count: this.distributionCount, //分配条数
        roleId: this.id, //公司id
      };
      this.distributionShow = false;

      let res = await headDistribute(params);
      console.log("已选择员工", res.data);
      if (res.data.statusCode == "00000") {
        Message.success(res.data.message);
        loading.close();
        this.distributionCount = 0;
        this.getList();
      } else {
        loading.close();
        Message.error(res.data.message);
      }
    },

    goBack() {
      this.$router.go(-1);
    },
    getCookie(name) {
      const cookieArr = document.cookie.split("; ");
      for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");
        if (cookiePair[0] === name) {
          return cookiePair[1];
        }
      }
      return JSON.stringify([]);
    },
    setisDivVisible(bool) {
      if (!bool) {
        this.company = null;
        this.industry = null;
        this.customerSource = null;
        this.userRealname = null;
        this.value1 = null;
        this.createTime = null;
      }
      this.isDivVisible = bool;
    },
    getNewLabel(oldLabel, aprop) {
      // console.log("this.newColumnsData", this.newColumnsData);
      let name = oldLabel;
      this.newColumnsData.forEach((v) => {
        if (v.prop == aprop) {
          name = v.label;
        }
      });
      return name;
    },
    // 根进记录
    async record() {
      let params = {
        phoneId: this.currentRow.id,
      };
      let res = await phoneFindOneHistoryPhone(params);
      let data = res.data.data;
      this.upRecords = [];
      console.log("获取跟进记录", data);

      if (data.historyRecord == "") {
        data.historyRecord.split("").forEach((v) => {
          this.upRecords.push(this.initArr(v));
        });
      } else {
        data.historyRecord.forEach((v) => {
          this.upRecords.push(this.initArr(v));
        });
      }
      // console.log('获取跟进记录2', this.upRecords);

      // this.drawer = true;
    },
    // soundTableDate
    async getSoundList(activePhone) {
      let params = {
        page: 1,
        size: 100,
        // userId: this.selectId,
        passivePhone: activePhone, //根据被叫查
        // callStarttime: this.callStarttime,
        // callEndtime: this.callEndtime,
        // activePhone: this.activePhone, //根据主叫查
        // second: this.second,
      };
      let res = await callLogList(params);
      // console.log("res666", res.data.data);
      this.soundTableDate = res.data.data;
    },
    // 将跟进历史数据格式化
    initArr(str) {
      let obj = {
        data: str.substr(-19),
        content: str.slice(0, -20),
      };
      return obj;
    },
    // 双击事件
    dblclick(row) {
      this.getSoundList(row.activePhone);
      this.currentRow = { ...row };
      this.record();
      this.wrapVisible = true;
    },
    // 筛选禁止复制的公司
    filterEnterprise() {
      // let arr = [2]
      let arr = [5805];
      if (arr.includes(localStorage.getItem("parentId") * 1)) {
        return "no-select";
      }
    },
    // 导入号码的关闭事件
    clearNumber() {
      this.fileList = [];
      this.file = {};
      this.$refs.upload.clearFiles();
    },
    // 批量申请号码
    // this.numsArray
    allApply() {
      if (this.numsArray.length == 0) {
        Message.warning("未选中号码");
        return;
      }
      this.$confirm("确定要批量申请这些号码吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(async () => {
          let userId = JSON.parse(localStorage.getItem("id"));
          let params = {
            listId: this.numsArray,
            userId,
          };
          let res = await phoneApplyUserPhoneApplyFor(params);
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
          } else {
            Message.error(res.data.message);
          }
        })
        .catch(() => {
          Message.info("已取消");
        });
    },
    // 申请号码
    apply(data) {
      this.$confirm("确定要申请这段号码吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(async () => {
          let userId = JSON.parse(localStorage.getItem("id"));
          let params = {
            listId: [data.id],
            userId,
          };
          let res = await phoneApplyUserPhoneApplyFor(params);
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
          } else {
            Message.error(res.data.message);
          }
        })
        .catch(() => {
          Message.info("已取消");
        });
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 分配到呼叫任务
    async taskCallBtn() {
      Message.warning("分配号码中,请稍等...");
      this.taskCall = false;
      this.distributeShow = false;
      this.taskShow = false;

      let taskTime = this.getYMDHMS(
        "YYYY-mm-dd HH:MM:SS",
        this.taskForm.taskTime
      );

      let newArr = this.tableData.filter(
        (v) => this.numsArray.includes(v.id) && v.status != 4
      );
      let backArr = [];
      backArr = this.tableData.filter(
        (v) => this.numsArray.includes(v.id) && v.status == 4
      );
      let listId = newArr.map((v) => v.id);
      let params = {
        taskName: this.taskForm.taskName,
        taskType: 0,
        listId,
        userId: this.userId,
        taskTime,
      };

      let res = await phoneDistribute(params);

      if (backArr.length != 0) {
        Message.warning(
          `不能将黑名单客户分配给员工,本次过滤了${backArr.length}个黑名单客户`
        );
      }

      this.getList();
    },
    // 分配到呼叫任务弹出框
    taskSubmit() {
      this.taskCall = true;
    },
    // 下一步
    nextSubmit() {
      this.taskShow = true;
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    //选择table表格
    handleSelectionChange(val) {
      this.numsArray = [];
      val.forEach((item) => {
        this.numsArray.push(item.id);
      });
      // console.log(this.numsArray)
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    //查询用户下所有员工
    findUser() {
      let params = {};
      let res = phoneFindUser(params);
      res.then((res) => {
        this.underDepart = res.data.data.depart;
        this.underUser = res.data.data.user;
      });
    },

    // 补0
    fillZero(n) {
      return n <= 9 ? "0" + n : n;
    },

    // 获取年月日的函数
    getYmd(data) {
      let y = data.getFullYear(); //年
      let m = data.getMonth() + 1; //月
      let d = data.getDate(); //日
      return [y, this.fillZero(m), this.fillZero(d)].join("-");
    },

    // 获取时间的函数
    getHms(times) {
      let h = times.getHours(); //时
      let m = times.getMinutes(); //分
      let s = times.getSeconds(); //秒
      return [this.fillZero(h), this.fillZero(m), this.fillZero(s)].join(":");
    },

    // 批量回收
    moreDelete() {
      // console.log(this.tableData);
      if (this.numsArray.length == 0) {
        Message.warning("未选中号码");
        return;
      }

      this.allRemoveVisible = true;
    },

    // 确认批量回收
    submitDeleteList() {
      this.numsArray.map((v, i) => {
        this.tableData.findIndex(async (item) => {
          if (item.status == 3 || item.status == 4) {
            Message.warning("选中列表中包含成交客户或黑名单，无法回收");
            return false;
          } else {
            let params = {
              listId: this.numsArray,
              recycleBin: 0,
            };
            let res = await phoneRecycleBin(params);
            Message.success("回收成功");
            this.numsArray.map((v, i) => {
              let index = this.tableData.findIndex((item) => item.id === v);
              this.tableData.splice(index, 1);
            });
          }
        });
      });

      this.allRemoveVisible = false;
    },

    //搜索
    handleSearch() {
      // this.getList();
      let obj = {};
      let obj1 = {};
      let obj2 = {};

      // 转换日期
      let sta_createTime = null;
      let end_createTime = null;
      if (this.createTime) {
        sta_createTime =
          this.getYmd(this.createTime[0]) +
          " " +
          this.getHms(this.createTime[0]);
        end_createTime =
          this.getYmd(this.createTime[1]) +
          " " +
          this.getHms(this.createTime[1]);
      }

      let sta_changeTime = null;
      let end_changeTime = null;
      if (this.changeTime) {
        sta_changeTime =
          this.getYmd(this.changeTime[0]) +
          " " +
          this.getHms(this.changeTime[0]);
        end_changeTime =
          this.getYmd(this.changeTime[1]) +
          " " +
          this.getHms(this.changeTime[1]);
      }

      let sta_getTime = null;
      let end_getTime = null;
      if (this.getTime) {
        sta_getTime =
          this.getYmd(this.getTime[0]) + " " + this.getHms(this.getTime[0]);
        end_getTime =
          this.getYmd(this.getTime[1]) + " " + this.getHms(this.getTime[1]);
      }

      let params = {};
      this.page = 1;
      // console.log('>>>>>',getList);
      params = {
        page: this.page,
        size: this.size,
        roleId: 0,
        activePhone: this.activePhone,
        company: this.company,
        status: this.value1,
        sta_createTime: sta_createTime,
        end_createTime: end_createTime,
        sta_changeTime: sta_changeTime,
        end_changeTime: end_changeTime,
        sta_getTime: sta_getTime,
        end_getTime: end_getTime,
        choosePeople: this.choosePeople,
        customerScale: this.customerScale,
        customerSource: this.customerSource,
        userRealname: this.userRealname,
        industry: this.industry,
        address: this.address,
        remarks: this.notes,
      };
      if (!params.activePhone) params.activePhone = null;

      let res = phoneList(params);
      res.then((res) => {
        let data = res.data;
        if (data.statusCode == "50000") {
          // this.cutIndex = oldIndex
          // data = res.data.data
          Message.warning(data.message);
          return false;
        }
        if (data.data) {
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;
        }
        res.data.data.forEach((item) => {
          let str = item.activePhone;
          item.phone = str.substring(0, 3) + "****" + str.substring(7);
        });
      });
    },
    //重置
    reset() {
      this.notes = null;
      this.industry = null;
      this.customerSource = null;
      this.createTime = null;
      this.changeTime = null;
      this.getTime = null;
      this.activePhone = null;
      this.userRealname = null;
      this.userId = null;
      this.departId = null;
      this.value = null;
      this.company = null;
      this.changeNums = null;
      (this.value1 = null), this.getList();
    },
    //分配号码
    distributeNums() {
      this.findUser();
      this.departId = null;
      this.userId = null;
      this.distributeShow = true;
    },

    //分配号码确认提交
    async distributeSubmit() {
      Message.warning("分配号码中,请稍等...");
      this.taskShow = false;
      this.distributeShow = false;

      let newArr = this.tableData.filter(
        (v) => this.numsArray.includes(v.id) && v.status != 4
      );
      let backArr = [];
      backArr = this.tableData.filter(
        (v) => this.numsArray.includes(v.id) && v.status == 4
      );
      let listId = newArr.map((v) => v.id);

      if (backArr.length != 0) {
        Message.warning(
          `不能将黑名单客户分配给员工,本次过滤了${backArr.length}个黑名单客户`
        );
      }

      if (this.userId == "" || this.userId == null) {
        let params = {
          listId,
          departId: this.departId,
        };
        let res = await phoneDistribute(params);
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
        } else {
          Message.error(res.data.message);
        }
      } else {
        let params = {
          listId,
          userId: this.userId,
        };
        let res = await phoneDistribute(params);
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.numsArray = [];
        } else {
          Message.error(res.data.message);
          this.numsArray = [];
        }
      }

      this.departId = null;
      this.userId = null;
      this.getList();
    },
    // 展示
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        roleId: 0,
        // recycleBin: 0,
        activePhone: this.activePhone,
        company: this.company,
        status: this.value1,
        createTime: this.createTime,
        changeTime: this.changeTime,
        getTime: this.getTime,
        choosePeople: this.choosePeople,
        customerScale: this.customerScale,
        customerSource: this.customerSource,
        industry: this.industry,
        address: this.address,
      };
      if (!params.activePhone) params.activePhone = null;

      let res = phoneList(params);
      res.then((res) => {
        let data = res.data;
        if (data.statusCode == "50000") {
          // this.cutIndex = oldIndex
          // data = res.data.data
          Message.warning(data.message);
        }

        if (data.data) {
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;
        }

        res.data.data.forEach((item) => {
          let str = item.activePhone;
          item.phone = str.substring(0, 3) + "****" + str.substring(7);
        });
      });
    },

    //编辑按钮
    update(row) {
      this.updateVisible = true;
      // console.log(row.row);
      this.form = row.row;
      this.update_status = null;
    },
    // 回收按钮
    removeButton(data) {
      if (data.status == 3) {
        Message.warning("此号码为成交客户，无法回收");
        return;
      } else if (data.status == 4) {
        Message.warning("此号码为黑名单，无法回收");
        return;
      }

      let params = {
        listId: [data.id],
        recycleBin: 0,
      };

      this.removeParams = params;
      this.removeVisible = true;
    },
    // 回收确认事件
    submitRemoveValue() {
      let res = phoneRecycleBin(this.removeParams);
      res.then((res) => {
        Message.success("回收成功");
        this.tableData.splice(this.removeIndex, 1);
        this.removeVisible = false;
        this.getList();
      });
    },
    //编辑提交
    updateSubmit() {
      if (this.update_status == null) {
        Message.warning("请选择状态!!!");
      } else {
        let params = {
          activePhone: this.form.activePhone,
          customerPhone: this.form.customerPhone,
          company: this.form.company,
          remarks: this.form.remarks,
          status: this.update_status,
          userId: this.form.userId,
          departId: this.form.departId,
          id: this.form.id,
          roleId: this.form.roleId,
          choosePeople: this.form.choosePeople,
          customerScale: this.form.customerScale,
          customerSource: this.form.customerSource,
          industry: this.form.industry,
          address: this.form.address,
        };
        let res = phoneUpdate(params);
        res.then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
            this.updateVisible = false;
            this.getList();
          } else {
            Message.error(res.data.message);
            this.updateVisible = false;
            this.getList();
          }
        });
      }
    },
    //下载模板
    upload() {
      window.open("http://124.70.206.131:9005/phone.xls", "_self");
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      Message.warning(`当前限制选择 1 个文件，请勿重复上传`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    compareArrays(arr1, arr2) {
      return JSON.stringify(arr1) === JSON.stringify(arr2);
    },
    changeUpload(file, fileList) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        console.log("jsonData", jsonData);
        // 获取表头行数据
        const headerRow = jsonData[0];
        let titleArr = [
          "公司",
          "客户姓名",
          "手机号",
          "客户分类",
          "备注",
          "坐席账户",
          "部门名字",
          "行业",
          "地址",
        ];
        console.log(
          "headerRow",
          headerRow,
          this.compareArrays(headerRow, titleArr)
        );
        // 根据您的需求编写表头校验逻辑
        if (this.compareArrays(headerRow, titleArr)) {
          this.file = file.raw;
          // resolve();
        } else {
          setTimeout(() => {
            this.file = {};
            this.$refs.uplaod.clearFiles();
          }, 1000);
          this.$message.error("请勿修改表头!");
        }
      };
      reader.onerror = () => {
        this.$message.error("读取文件失败!");
      };
      reader.readAsArrayBuffer(file.raw);
    },

    allNums() {
      this.disabled = false;
      this.numShow = true;
      this.formDate = null;
    },
    //批量导入提交
    async numsSubmit(index) {
      console.log("导入前限制大小", this.file);
      const isLt2M = this.file.size / 1024 / 1024 < 3;

      if (!this.file.size) {
        this.$message.warning("上传的文件不能为空!");
        return false;
      } else if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 3MB!");
        return false;
      }

      console.log("导入前限制大小", XlsxPopulate);
      let formDate = new FormData();
      // 完整上传
      formDate.append("file", this.file);
      Message.success("数据加载中，稍等！请勿离开页面");

      const loading = await this.$loading({
        lock: true,
        text: "导入号码中,请稍等...",
      });

      if (this.numberTest) {
        formDate.append("phoneTest", 1);
      }

      let res = await phoneImportPhone(formDate);
      if (res.data.statusCode == "00000") {
        loading.close();
        Message.success(res.data.message);
        this.file = {};
      } else {
        loading.close();
        Message.error(res.data.message);
        this.file = {};
      }
      this.disabled = true;
      this.numShow = false;
    },
    //添加号码
    addNums() {
      this.form = [];
      this.addOneVisible = true;
    },
    //添加号码确认
    addOneSubmit() {
      if (!this.form.activePhone) {
        Message.warning("客户号码不能为空~");
        return false;
      }
      let params = {
        activePhone: this.form.activePhone,
        customerPhone: this.form.customerPhone,
        company: this.form.company,
        departName: this.form.departName,
        remarks: this.form.remarks,
        status: this.update_status,
        userId: this.id,
        choosePeople: this.form.choosePeople,
        customerScale: this.form.customerScale,
        industry: this.form.industry,
        address: this.form.address,
        customerSource: this.form.customerSource,
      };
      let res = phoneAdd(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.addOneVisible = false;
        } else {
          Message.error(res.data.message);
          this.addOneVisible = false;
        }
        this.getList();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.vipSearch {
  // padding: 20px 0;
  // padding-right: 20px;
  // box-sizing: border-box;
  // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  // border-radius: 7px;
}

.no-select {
  -moz-user-select: none;
  /* Firefox私有属性 */
  -webkit-user-select: none;
  /* WebKit内核私有属性 */
  -ms-user-select: none;
  /* IE私有属性(IE10及以后) */
  -khtml-user-select: none;
  /* KHTML内核私有属性 */
  -o-user-select: none;
  /* Opera私有属性 */
  user-select: none;
  /* CSS3属性 */
}

/deep/.el-button--success,
.el-button--primary {
  color: #fff;
  background-color: #3278fe;
  border-color: #3278fe;

  &:hover {
    opacity: 0.7;
  }
}

#companyNums {
  height: 100%;
  display: flex;
  flex-direction: column;

  .el-card {
    flex: 1;
    box-shadow: none;
    overflow-y: scroll;
  }

  .num-upload {
    /deep/ .el-dialog__body {
      display: flex;
      justify-content: space-between;

      .left {
        margin-right: 8px;
      }

      .right {
        flex: 1;
      }
    }
  }

  header {
    h1 {
      font-weight: normal;

      > span {
        font-size: 18px;
        color: #606369;
      }
    }

    .search {
      display: flex;
      margin-top: 10px;
      flex-direction: column;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;

        .left-wrap {
          width: 20%;
          display: flex;
          align-items: center;

          .el-select {
            width: 100%;
          }

          .el-date-editor {
            width: 100% !important;
          }

          span {
            margin: 6px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .right {
        // margin-top: 10px;
        // text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
  }

  // main {
  .pagination {
    width: 100%;
    // height: 40px;
    // border: 1px solid red;
    background-color: #fff;
    // bottom: 0;
    // right: 0;
    // z-index: 99;
    text-align: right;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    box-sizing: border-box;
  }

  // }

  .uploadBtn {
    width: 68%;
  }

  /deep/.el-dialog {
    width: 70%;
  }

  .update {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;

    .el-form-item {
      width: 50%;
    }
  }
}
</style>